.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  background-color: #000000;
  color: white;
  padding: 10px;
  text-align: center;
}

.footer {
  background-color: #2B2B2F;
  color: white;
  padding: 10px;
}

.navbarLinks{
  color: #e7dcbc;
}